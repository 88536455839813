<template>
  <div class="collect_content">
    <div class="desc_box">
      <div class="title">
        快速將多個錢包內的餘額<br />
        歸集到一個錢包裡
      </div>
      <div class="desc_item">
        <img src="../assets/img/w1.png" class="icon" srcset="" />
        <div>
          <div class="desc_title">選擇公鏈</div>
          <div class="desc_text">選擇冷钱包所属公鏈</div>
        </div>
      </div>
      <div class="desc_item">
        <img src="../assets/img/w3.png" class="icon" srcset="" />
        <div>
          <div class="desc_title">上傳歸集錢包私鑰</div>
          <div class="desc_text">下載模板後上傳表格</div>
        </div>
      </div>
      <div class="desc_item">
        <img src="../assets/img/w7.png" class="icon" srcset="" />
        <div>
          <div class="desc_title">歸集錢包</div>
          <div class="desc_text">歸集錢包時需要耗點時間，請耐心等待</div>
        </div>
      </div>
      <div class="desc_bg"></div>
    </div>
    <div class="main_box">
      <my-scroll>
        <div class="base_title base_title_wallet">選擇公鏈</div>
        <div class="base_select mt20" :class="{ select_active: show1 }">
          <div class="content">
            <img
              :src="chainObj[selectChain].logo"
              class="select_img"
              srcset=""
            />{{ chainObj[selectChain].chainName }}
          </div>
          <div class="right_down" @click.stop="show1 = !show1,show3=false"></div>
          <div class="downlist" v-click-outside="handleScroll">
            <div
              class="list_item"
              v-for="(item, index) in chains"
              :key="index"
              @click="switchChain(item.chain)"
            >
              <img :src="item.logo" class="select_img" srcset="" />{{
                item.chainName
              }}
            </div>
          </div>
        </div>
        <div class="both_side mt20">
          <div class="base_title base_title_wallet" style="margin: 0">
            歸集代幣
          </div>
          <img src="../assets/img/contract.png" class="contract_img" srcset="" />
        </div>
        <div class="base_input_select mt20" :class="{ select_active: show3 }">
          <input
            type="text"
            placeholder="請選擇代幣或粘貼代幣合約地址"
            v-model="selectToken.Symbol"
            @input="addressInput"
          />
          <div class="right_down" @click.stop="show3 = !show3,show1=false"></div>
          <div class="downlist" v-click-outside="handleScroll">
            <div
              class="list_item"
              v-for="(item, index) in Tokens"
              @click="selToken(index)"
              :key="item.Symbol"
            >
              <div class="align_center">
                <img v-if="item.logo" :src="item.logo" class="select_img" alt="" srcset="">
                <div class="content">
                  {{ item.Symbol }}
                  <div class="name">{{ item.address }}</div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div class="both_side">
          <div class="base_title">單個地址歸集數量</div>
          <div class="fix_switch">
            固定數量<i-switch size="large" v-model="status"  @on-change="changeStatus"/>
          </div>
        </div>
        <div class="base_input mt20">
              <input v-show="!status"
                type="text"
                placeholder="輸入最小歸集數量，即該地址數量＞＝該值時餘額全部歸集"
                v-model="sendAmount"
              />
              <input v-show="status"
                type="text"
                placeholder="輸入歸集數量，若該地址餘額不足將自動跳過"
                v-model="sendAmount"
              />
        </div>
        <div class="col_2 mt20 mobile_br">
          <div class="col_2_item mboile_20">
            <div class="base_title">接收地址</div>
            <div class="base_input">
              <input type="text"  :class="{through:typeStatus2}" :disabled="typeStatus2" placeholder="輸入接收地址" v-model="recipient" />
            </div>
          </div>
          <div class="col_2_item mboile_0">
            <div class="base_title">接收模式</div>
            <div class="base_switch">
              <div class="switch_box">
              <span class="name">多轉一</span>
              
              <i-switch
                size="large"
                v-model="typeStatus" @on-change="changeType"
              />
            </div>
            <div class="switch_box">
              <span class="name">一轉一</span>
              
              <i-switch
                size="large"
                v-model="typeStatus2" @on-change="changeType2"
              />
            </div>
            </div>
            
          </div>
        </div>
        <div v-show="uploadResult.length == 0">
          <div class="private_title">
            <span class="title">{{typeStatus2?'上傳私鑰及接收地址':'上傳私鑰'}}  </span>
          </div>
          <uploadExcel :downfile="(typeStatus2?'collect1':'collect2')"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"></uploadExcel>
        </div>
        <div v-show="uploadResult.length">
          <div class="private_title">
            <div class="align_center">
              <span class="title">地址信息</span>
              <div class="refresh_box" @click="refreshBalance">
                <img src="../assets/img/a4.png" class="refresh_icon" :class="{refresh_animate:refresh}"/>
                <span class="total_num">刷新</span>
              </div>
            </div>
            <img src="../assets/img/close2.png" class="close" @click="removeAdd" />
          </div>
          <div class="base_box address_list">
            <div class="table_item table_header">
                <div class="status"></div>
                <div class="no"></div>
                <div class="address">歸集地址</div>
                <div class="address">接收地址</div>
                <div class="amout">{{Tokens[0].Symbol}}餘額Gas</div>
                <div class="amout">{{selectToken.Symbol}}餘額</div>
                <div class="delete"></div>
            </div>
            <div class="table_content">
              <my-scroll>
              <div class="table_item" v-for="(item,index) in wallets" :key="index">
               <img src="../assets/img/a1.png" class="status" srcset="" v-show="!item.loading && item.status">
                <img src="../assets/img/a2.png" class="status" srcset="" v-show="!item.loading && !item.status">
                <div class="status" v-show="item.loading"></div>
                <div class="no">{{index+1}}</div>
                <div class="address">{{showAccount4(item.address)}}</div>
                <div class="address">{{showAccount4(item.recipient)}}</div>
                <div class="amout">{{item.showBalance}}</div>
                <div class="amout">{{!tokenAddress?item.showBalance:item.showTokenBalance}}</div>
                <img src="../assets/img/a3.png" @click="deleteAdd(index)" class="delete" srcset="">
              </div>
            </my-scroll>
            </div>
            <div class="table_item table_footer">
                <div class="status"></div>
                <div class="address" style="color:#000">縂操作 {{wallets.length}}</div>
                <div class="address" style="color:#000">執行成功 {{successNum}}</div>
                <div class="amout">{{totalBalance}} {{Tokens[0].Symbol}}</div>
                <div class="amout">{{totalTokenBalance}} {{selectToken.Symbol}}</div>
                <div class="delete"></div>
            </div>
            <div class="error_box" v-show="errors.length">
              <div class="error_title">
                <span class="text">以下地址無效或金额無效無法發送</span>
                <div class="delete_box" @click="deleteAll">
                  <img src="../assets/img/a3.png" alt="" srcset="">一鍵刪除
                </div>
              </div>
              <div>
                <span v-for="(item,index) in errors" :key="index">第{{item.index}}行<span v-show="index != errors.length-1">、</span></span>无效
              </div>
            </div>
          </div>
        </div>
        <div class="base_btn2" @click="clickpersonal" v-if="personal">簽名驗證</div>
        <div class="base_btn2" @click="batchCollectToken" v-else>歸集錢包</div>
      </my-scroll>
    </div>
  </div>
</template>

<script>
import { chainObj } from "../config";
import { ERC20_ABI } from "../config";
import uploadExcel from "@/components/uploadExcelPrivateKey";
import {
  accMul,
  accSub,
  formatAmount,
  parseAmount,
  toFixed,
} from "@/utils/formatBalance";
import { BigNumber } from "ethers";
import {
  showFromWei,
  showFromWeiMore,
  showAccount4,
  toWei,
  clickOutside
} from "../utils";
//计算对象数组中某个属性合计
function countTotal(arr, keyName) {
	let $total = 0;
	$total = arr.reduce(function (total, currentValue, currentIndex, arr){
	    return currentValue[keyName] ? (total + parseFloat(currentValue[keyName])) : total;
	}, 0);
	return $total;
}

export default {
  directives: {clickOutside},
  components: { uploadExcel },
props: {
    address: {
      type: String,
    }
  },
  data() {
    return {
      chainObj,
      show1: false,
      selectChain: "BSC",
      rpcUrl: chainObj["BSC"].RPC,
      show3: false,
      Tokens: chainObj["BSC"].Tokens,

      selectToken: {},
      tokenAddress: "",
      tokenDecimals: "",
      uploadResult: [],
      recipient: "",
      sendAmount: "",
      personal: true,
      status: false,
      typeStatus: true,
      typeStatus2: false,
      wallets: [],
      refresh: false,
      errors: []
    };
  },
  computed: {
    chains() {
      return Object.values(this.chainObj);
    },
    successNum() {
      return this.wallets.filter(item => item.status).length
    },
    totalBalance() {
      let number = countTotal(this.wallets, 'showBalance')
      return String(number).replace(/^(.*\..{6}).*$/,"$1");
    },
    totalTokenBalance() {
      if(!this.tokenAddress) {
        let number = countTotal(this.wallets, 'showBalance')
      return String(number).replace(/^(.*\..{6}).*$/,"$1");
      } else {
        let number = countTotal(this.wallets, 'showTokenBalance')
      return String(number).replace(/^(.*\..{6}).*$/,"$1");
      }
    },
  },
  // watch: {
  //   wallets: {
  //     handler (val) {
  //       let errors = []
  //       if(val && val.length) {
  //         val.forEach((item,index) => {
  //           if(!item.address || item.showTokenBalance==0 || item.showBalance == 0) {
  //             errors.push(index+1)
  //           }
  //         })
  //       }
  //       this.errors = errors
  //       console.log(this.errors)
  //     },
  //    immediate: true,
  //     // 这里是关键，代表递归监听 demo 的变化
  //     deep: true
  //   }
  // },
  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll, true);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.handleScroll, true);
  // },
  methods: {
    showAccount4,
    deleteAll() {
      let wallets = this.wallets
      this.errors.forEach(item => {
        let index = wallets.findIndex(wallet => wallet.address == item.address)
        wallets.splice(index, 1)
      })
      this.uploadResult = wallets
      this.wallets = wallets
      this.errors = []
    },
    removeAdd() {
      this.uploadResult = []
      this.wallets = []
    },
    changeStatus() {
      this.sendAmount = ''
    },
    changeType() {
      console.log(this.typeStatus)
      console.log(this.typeStatus2)
      this.typeStatus2 = !this.typeStatus
    },
    changeType2() {
      this.typeStatus = !this.typeStatus2
      if(this.typeStatus2) {
        this.recipient = ''
      }
    },
    handleScroll() {
      this.show1 = false;
      this.show3 = false;
    },
    deleteAdd(index) {
      this.wallets.splice(index, 1);
    },
    switchChain(chain) {
      console.log(chain)
      this.selectChain = chain;
      this.rpcUrl = chainObj[chain].RPC;

      this.Tokens = chainObj[chain].Tokens;
      this.tokenAddress = "";
      this.selectToken = {}
      this.show1 = false;
    },
    addressInput() {
      console.log(this.selectToken.Symbol);
      if (this.selectToken.Symbol && this.selectToken.Symbol.length > 5) {
        this.tokenAddress = this.selectToken.Symbol;
        this.show3 = false;
        this.confirmToken();
      }

    },
    //选择交易币种
    selToken(index) {
      if (this.selectToken.address == this.Tokens[index].address) return;
      this.selectToken = JSON.parse(JSON.stringify(this.Tokens[index]));
      this.tokenAddress = this.Tokens[index].address;
      this.tokenDecimals = this.Tokens[index].decimals;
      console.log(this.selectToken)
      console.log(this.tokenAddress)
      this.show3 = false;
      if(this.wallets.length) {
          this.batchGetTokenBalance()
        }
    },
    //确定代币合约地址
    async confirmToken() {
      let tokenAddress = this.tokenAddress;

      this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
      try {
        var Web3 = require("web3");
      const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));

        const tokenContract = new myWeb3.eth.Contract(ERC20_ABI, tokenAddress);
        let tokenSymbol = await tokenContract.methods.symbol().call();
        let tokenDecimals = await tokenContract.methods.decimals().call();
        tokenDecimals = parseInt(tokenDecimals);
        console.log(tokenSymbol, tokenDecimals);

        this.tokenDecimals = tokenDecimals;
        this.tokenSymbol = tokenSymbol;
        this.selectToken.Symbol = tokenSymbol;
        if(this.wallets.length) {
          this.batchGetTokenBalance()
        }
      } catch (e) {
        console.log("e", e);
      } finally {
        this.message.close()
      }
    },
    
    beforeUpload(file) {
      if(!this.tokenAddres && !this.selectToken.Symbol) {
        this.$message.error("請選擇代幣或粘貼代幣合約地址");
        return false
      }
      if(this.typeStatus && !this.recipient) {
        this.$message.error("請輸入接收地址");
        return false
      }
      if(this.typeStatus && this.recipient.length != 42) {
        this.$message.error("請輸入正確的接收地址");
        return false
      }
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }
      this.$message.error("Please do not upload files larger than 1m in size.");
      return false;
    },
    handleSuccess({ header, results }) {
      console.log("接收到的结果", header, results)
      if(this.typeStatus2 && header.length == 1) {
        this.$message.error("上傳私鑰及接收地址");
        return
      }
      if (results.length > 0) {
        // 拿到秘钥
        this.uploadResult = results;
        
        console.log(results);
        var Web3 = require("web3");
        const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
        console.log(myWeb3.eth.accounts);
        try {
          this.uploadResult.forEach(async (item) => {
            let account = myWeb3.eth.accounts.privateKeyToAccount(
              item.privateKey
            );
            console.log(account);
            item.address = account?.address;
            if(this.typeStatus) {
              item.recipient = this.recipient
            }
            item.status = false
            item.loading = true
          });
          this.wallets = this.uploadResult
          this.batchGetTokenBalance()
          console.log(this.uploadResult);
        } catch (error) {
          console.log(error);
          this.$message.error("privateKey Error");
        }
      }
    },
    async refreshBalance() {
      this.refresh = true
      setTimeout(() => {
        this.refresh = false
      }, 1000)
      setTimeout(() => {
          let wallets = this.wallets;
          let length = wallets.length;
          this.errors = []
          for (let index = 0; index < length; index++) {
            wallets[index].loading = true
            wallets[index].status = false
              this.getTokenBalance(wallets[index], index, 'refresh');
          }
      }, 30);
    },
    async batchGetTokenBalance() {
        
        setTimeout(() => {
            let wallets = this.wallets;
            let length = wallets.length;
            this.errors = []
            for (let index = 0; index < length; index++) {
              wallets[index].loading = true
                this.getTokenBalance(wallets[index], index);
            }
        }, 30);
    },
    async getTokenBalance(wallet, index, refresh) {
        try {
            
            var Web3 = require("web3");
            const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
            if(this.tokenAddress) {
              const tokenContract = new myWeb3.eth.Contract(ERC20_ABI, this.tokenAddress);
              let tokenBalance = await tokenContract.methods.balanceOf(wallet.address).call();
              console.log("tokenBalance",tokenBalance,this.tokenDecimals)
              let showTokenBalance = showFromWei(tokenBalance, this.tokenDecimals, 4);
              wallet.tokenBalance = tokenBalance;
              wallet.showTokenBalance = showTokenBalance;
            }
            
            let balance = await myWeb3.eth.getBalance(wallet.address);
            let showBalance = showFromWei(balance, 18, 4);
            wallet.balance = balance;
            wallet.showBalance = showBalance;

            // if(!wallet.address || wallet.showTokenBalance==0 || wallet.showBalance == 0) {
            //   this.errors.push(index+1)
            // }

            this.$set(this.wallets,index,wallet)
        } catch (e) {
            console.log("e", e);
        } finally {
          if(!wallet.address || wallet.showTokenBalance==0 || wallet.showBalance == 0) {
              this.errors.push({
                index: index+1,
                address: wallet.address
              })
            }
          if(refresh == 'refresh' && index == (this.wallets.length - 1)) {
            this.$message('刷新成功');
          }
        }
    },
    async clickpersonal() {
      let that = this
      const web3 = window.web3;
     
      web3.eth.personal.sign("check_wallet", this.address).then(async(tx) => {
        that.personal = false
        localStorage.setItem('personal', true)
      })
    },
    async batchCollectToken() {
        if (!this.recipient && this.typeStatus) {
          this.$message.error("請輸入接收地址");
          return;
        }
        this.message2 = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
        let wallets = this.wallets;
        let length = wallets.length;
        for (let index = 0; index < length; index++) {
            this.collectToken(wallets[index], index);
        }
    },
    
    async collectToken(wallet, index) {
      var Web3 = require("web3");
      const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
      const gasPrice = await myWeb3.eth.getGasPrice();
      
      try {
        console.log(!this.tokenAddress)
        // native token send
        if (!this.tokenAddress) {
          const gas = accMul(gasPrice.toString(), 21000);
          const allAmount = !this.status
            ? accSub(wallet.balance, gas)
            : parseAmount(this.sendAmount);
          const nonce = myWeb3.utils.toHex(
            await myWeb3.eth.getTransactionCount(wallet.address)
          );
          console.log(wallet.balance,allAmount)
          const transaction = {
            nonce: nonce,
            gasLimit: 21000,
            gasPrice: gasPrice,
            to: wallet.recipient,
            value: BigNumber.from(allAmount),
            data: "0x",
          };
          let signedTx = await myWeb3.eth.accounts.signTransaction(
            transaction,
            wallet.privateKey
          );
          console.log(signedTx)
          let res = await myWeb3.eth.sendSignedTransaction(signedTx.rawTransaction);
              wallet.loading = false
          if (!res.status) {
            wallet.status = false
          }
          wallet.status = true
          this.$set(this.wallets,index,wallet)
        } else {
          const tokenContract = new myWeb3.eth.Contract(
            ERC20_ABI,
            this.tokenAddress,
          );
          wallet.tokenBalance = await tokenContract.methods.balanceOf(wallet.address).call()
          console.log(wallet.tokenBalance)
          const allAmount = !this.status
            ? wallet.tokenBalance
            : parseAmount(this.sendAmount, this.tokenDecimals);
          console.log(allAmount)
          const gas = await tokenContract.methods.transfer(
            wallet.recipient,
            allAmount
          ).estimateGas({ from: wallet.address});
          const encodeData = tokenContract.methods.transfer(
            wallet.recipient,
            allAmount
          ).encodeABI();
          const nonce = await myWeb3.eth.getTransactionCount(wallet.address);
          const transaction = {
            nonce: nonce,
            gasLimit: parseFloat(toFixed(accMul(gas.toString(), 1.1), 0)),
            to: this.tokenAddress,
            value: "0x",
            data: encodeData,
            gasPrice: gasPrice,
          };
          console.log("transaction", transaction)
          let signedTx = await myWeb3.eth.accounts.signTransaction(
            transaction,
            wallet.privateKey
          );
          let res = await myWeb3.eth.sendSignedTransaction(signedTx.rawTransaction);
              wallet.loading = false
          if (!res.status) {
            wallet.status = false
          }
          wallet.status = true
          
          this.$set(this.wallets,index,wallet)
        }
      } catch (e) {
        console.log(e)
        wallet.loading = false
        wallet.status = false
        this.$set(this.wallets,index,wallet)
       
      } finally {
        if(this.message2 && index == (this.wallets.length - 1)) {
          setTimeout(()=> {
          this.message2.close()
          this.$message("執行完畢");
          }, 3000)
          
        }
        
      }
    
    },
  },
};
</script>

<style lang="less">
@import "../assets/pc/collect.less";
@import "../assets/mobile/collect.less";
</style>